@use '../../common-sass/animations.scss';

.faq-item-container {

  .faq-question {
    margin-bottom: 1rem;
    background-color:rgba(#EAECEE, 0.9);
    border-bottom: #2471A3 solid .5rem;
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-family: 'Suez One', sans-serif;

    &:hover {
      box-shadow: 0 1rem 2rem rgba(black,.8);
    }

    &-selected {
      text-align: left;
      background-color: #CCD1D1;
      box-shadow: 0 1rem 2rem rgba(black,.8);
      padding: 1rem 3rem;
    }
  }

  .faq-answer {
    margin-bottom: 1rem;
    background-color:rgba(#EAECEE, 0.8);
    text-align: left;
    padding: 1rem 3rem;
    font-size: 1.5rem;
    font-family: 'Arial', sans-serif;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 1rem 2rem rgba(black,.8);

    animation: fadeIn 1s ease-out;

    .bold {
      font-weight: bolder;
      font-family: 'Suez One';
      color: #0B5345;
    }

    &:hover {
      background-color: #EAECEE;
    }
  }
}