@use '../../common-sass/animations.scss';
@import '../../common-sass/media.scss';

.section-contact {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .section-border {
    width: 100%;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: url(../../../public/assets/contact.jpg);
    background-size: cover;
    background-position: center;
    filter: grayscale(75%);
    z-index: 1;
    opacity: .8;
  }

  .contact-container {
    width: 80vw;
    position: relative;
    flex: 1;
    padding: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;


    @media only screen and (max-width: $bp-medium) {
      width: 90vw;
      padding: 5rem;
    }

    .contact-header {
      background-color:rgba(white, 0.9);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      text-align: center;
      padding: 2rem;
      font-size: 5rem;
      font-family: 'Courier Prime', sans-serif;
      text-transform: capitalize;
      margin-bottom: 2rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: 2.8rem;
      }

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }
    }

    .contact-item {
      background-color: var(--color-primary);
      //border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      padding: 1rem 2rem;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: left;
      font-size: 1.5rem;
      font-family: 'Suez One';
      color: white;

      .social-media-logo {
        height: 2.5rem;

      }

      .contact-method {
        color: #2471A3;
        text-transform: uppercase;
      }

      .contact-value {
        margin-left: 1rem;
      }
    }
  }
}