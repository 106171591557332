.btn {
    color: white;
    font-size: 2rem;
    text-transform: capitalize;
    padding: 1rem 3rem;
    background-color: var(--color-primary);
    border-radius: 1rem;
    border-style: solid;
    border-width: .1rem;
    border-color: var(--color-primary);
    transition: all .2s;

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all .4s;
      background-color: var(--color-primary); 
    }

    &:hover {
      transform: translateY(-.5rem);
      box-shadow: 0 1rem 2rem rgba(black,.2);
      background-color: var(--color-primary);

      &::after {
          transform: scaleX(1.4) scaleY(1.6);
          opacity: 0;
      }
    }
  
    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba(black,.2);
    }
  }