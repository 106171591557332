@import '../../common-sass/media.scss';

.game-item {
  position: relative;
  border: solid .5rem var(--color-primary);
  border-radius: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;

  color: white;
  font-size: 2rem;
  font-family: 'Roboto';
  padding: .5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  opacity: 1;

  &:hover{
    transform: scaleX(1.05) scaleY(1.05);
    box-shadow: 0 1rem 2rem rgba(black,.9);
  }

  .game-item-topper {
    .game-item-header {
      padding: 1rem 1rem;
      background-color: rgba(white,.85);
      border-radius: 1rem;
      color: var(--color-primary);
      font-size: 1.3rem;
      text-align: left;
      font-family: 'Suez One';
      opacity: 1 !important;
  
      display: flex;
      flex-direction: row;
  
      @media only screen and (max-width: $bp-largest) {
        font-size: 100%;
      }
  
      @media only screen and (max-width: $bp-large) {
        font-size: 130%;
      }
  
      @media only screen and (max-width: $bp-medium) {
        font-size: 140%;
      }
  
      @media only screen and (max-width: $bp-smallest) {
        font-size: 130%;
      }
      
      .game-item-header-content {
        margin-left: 1rem;;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .game-availability {
      position: relative;
      margin-top: .2rem;
      text-align: right;
      
      .game-availability-text {
        color: white;
        padding: .5rem 1rem;
        font-size: 1rem;
        font-family: 'Suez One';
        border-radius: 1rem;

        @media only screen and (max-width: $bp-largest) {
          font-size: 100%;
        }
    
        @media only screen and (max-width: $bp-large) {
          font-size: 130%;
        }
    
        @media only screen and (max-width: $bp-medium) {
          font-size: 140%;
        }
    
        @media only screen and (max-width: $bp-smallest) {
          font-size: 130%;
        }
      }
    }
    .game-availability-new {
      background-color: rgba(green,.85);
    }
    .game-availability-coming {
      background-color: rgba(gray,.85);
    }
  }
 
  .game-difficulty {
    text-align: right;
    padding-right: 1rem;
    .game-difficulty-icon {
      color: white;
      fill: white;
    }
    .game-difficulty-icon-off {
      color: white;
      fill: white;
      opacity: .6;
    }
  }
}