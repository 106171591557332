@use '../../common-sass/google-icons.scss';
@import '../../common-sass/media.scss';

.section-games {

  .games-container {
    background-image: linear-gradient(to right, whitesmoke, lightgray);
    min-height: 90vh;
    padding: 2.5vh 2.5vh;
  
    display: flex;
    flex-direction: column;

    .games-header {
      font-size: 5rem;
      z-index: 100;
      
      @media only screen and (max-width: $bp-large) {
        align-self: center;
      }
    }

    .games-sub-header {
      font-size: 2rem;
      z-index: 100;

      @media only screen and (max-width: $bp-large) {
        align-self: center;
      }
    }

    .games-note {
      font-size: 1.5rem;
      z-index: 100;
      align-self: flex-end;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-large) {
        align-self: center;
      }
    }

    .games-body {
      padding: 4vh 5vw;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 5vw;
      row-gap: 5vh;

      @media only screen and (max-width: $bp-largest) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media only screen and (max-width: $bp-large) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (max-width: $bp-small) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}