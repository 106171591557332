.menu-item {
  padding: 1rem 2rem;
  cursor: pointer;
  //text-transform: capitalize;
  color: white;

  &:hover {
    font-weight: bolder;
    color: aqua;
    text-transform: uppercase;
  }
}