$sub-color: var(--color-primary-dark);
$main-color: var(--color-primary);

.group {
  position: relative;
  margin: 45px 0;

  .form-input {
    background: none;
    background-color: white;
    color: var(--color-primary);
    font-size: 1.8rem;
    padding: 1rem;
    margin-right: 1.5rem;
    border-radius: 1rem;
    border: 1px solid var(--color-primary);
    font-family: 'Courier Prime';

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(black,.8);
    }
  }

  .form-input-label {
    color: var(--color-primary);
    font-size: 3rem;
    pointer-events: none;
  }
}