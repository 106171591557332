@use '../../common-sass/animations.scss';
@import '../../common-sass/media.scss';

.game-main-container {
  z-index: 100;
  width: 100%;
  height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: fadeIn 2s ease-out;

  .game-details-container {
    position: relative;
    width: 60vw;
    min-height: 50vh;
    padding: 1.5rem 1.5rem;

    display: flex;
    flex-direction: row;

    &::before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(white, .8);
      border-radius: 1rem;
      border: solid .5rem var(--color-primary);
    }

    @media only screen and (max-width: $bp-largest) {
      width: 85vw;
    }

    @media only screen and (max-width: $bp-medium) {
      width: 90vw;
    }

    .game-details-card {
      width: 30%;
      border: solid .5rem var(--color-primary);
      border-radius: 1rem;
      position: relative;
      background-size: cover;
      background-position: center;

      animation: fadeIn 2s ease-out 1s;
      animation-fill-mode: backwards;

      &:hover{
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }

      @media only screen and (max-width: $bp-small) {
        display: none;
      }
    }

    .game-details-description-container {
      position: relative;
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      
      .game-details-description {

        width: 100%;
        z-index: 10;
        position: relative;

        display: flex;
        flex-direction: column;

        .game-details-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .game-details-title {
            font-family: 'Suez One', sans-serif;
            font-size: 5rem;
            color: var(--color-primary);
  
            animation: fadeIn 2s ease-out 1s;
            animation-fill-mode: backwards;
          }

          .game-details-flag-icon {
            font-size: 5rem;
            border-radius: 1rem;
            margin-right: .5rem;

            animation: fadeIn 2s ease-out 1s;
            animation-fill-mode: backwards;
          }
        }

        .game-details-description {
          margin: 0;
          margin-top: 1rem;
          font-family: 'Roboto';
          font-size: 1.5rem;

          animation: fadeIn 2s ease-out 1.5s;
          animation-fill-mode: backwards;

          @media only screen and (max-width: $bp-largest) {
            font-size: 350%;
          }

          @media only screen and (max-width: $bp-large) {
            font-size: 300%;
          }
        }

        .game-sub-details {
          display: flex;
          flex-direction: row;
          margin-top: 2rem;
          justify-content: right;

          animation: fadeIn 2s ease-out 2s;
          animation-fill-mode: backwards;
          
          .game-details-duration {
            margin-right: 1rem;
            font-size: 2.5rem;  
          }
  
          .game-details-price {
            font-size: 2.5rem;
          }
        }

        .game-details-difficulty {
          text-align: right;
          margin-right: 1rem;

          animation: fadeIn 2s ease-out 2s;
          animation-fill-mode: backwards;
        }

        .diff-easy {
          color: green !important;
          z-index: 1000;
        }

        .diff-medium {
          color: goldenrod !important;
          z-index: 1000;
        }

        .diff-hard {
          color: maroon !important;
          z-index: 1000;
        }

        .game-difficulty-icon-off {
          color: grey !important;
          z-index: 1000;
          opacity: .6;
        }
      }
      .game-not-available {
        font-family: 'Roboto';
        font-size: 1.5rem;
        text-align: right;
        margin-right: 1rem;

        @media only screen and (max-width: $bp-largest) {
          font-size: 350%;
        }

        @media only screen and (max-width: $bp-large) {
          font-size: 300%;
        }
      }

      .customer-email-form {
        position: relative;
        display: flex;
        flex-direction: column;

        animation: fadeIn 2s ease-out 2.5s;
        animation-fill-mode: backwards;

        .group {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: left;
          margin-bottom: 1rem;
        }

        .submit-button-container {
          display: flex;
          flex-direction: row;
          justify-content: right;
          position: relative;

          .btn-accept {
            font-family: 'Courier Prime';
            position: relative;
            width: fit-content;
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            border-radius: 1rem;
            z-index: 11;
    
            &::after {
              background-color: var(--color-primary);
            }
          }
        }
      }
    }
  }
}


