@import '../../common-sass/media.scss';

.success-container {
  min-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;

  background-size: cover;
  background-position: center;

  @media only screen and (min-width: $bp-small) {
    &::before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-image: url(../../../public/assets/game.jpg);
      background-size: cover;
      background-position: center;
      filter: grayscale(100%);
    }
  }
}