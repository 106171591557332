@import '../../common-sass/media.scss';

.header-container {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: var(--color-primary);
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 100;

  @media only screen and (max-width: $bp-small) {
    font-size: 1.7rem;
  }
}