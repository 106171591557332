@use '../../common-sass/animations.scss';
@import '../../common-sass/media.scss';

.section-faq {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .section-border {
    width: 100%;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: url(../../../public/assets/faq.jpg);
    background-size: cover;
    background-position: center;
    //background-color: #2C3E50;
    filter: grayscale(80%);
    z-index: 1;
    opacity: .7;
  }

  .faq-container {
    width: 80vw;
    position: relative;
    flex: 1;
    padding: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;


    @media only screen and (max-width: $bp-medium) {
      width: 90vw;
      padding: 5rem;
    }

    .faq-header {
      background-color:rgba(white, 0.9);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      text-align: center;
      padding: 2rem;
      font-size: 5rem;
      font-family: 'Courier Prime', sans-serif;
      text-transform: capitalize;
      margin-bottom: 2rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: 2.8rem;
      }

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }
    }
  }
}