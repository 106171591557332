@import './common-sass/media.scss';

:root {
  --color-primary: #00204a;
  --color-primary-light: #000065;
  --color-primary-dark: #000088;

  --color-primary-highlight: steelblue;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
  scroll-behavior: smooth;
  
  @media only screen and (max-width: $bp-largest) {
      font-size: 50%;
  }

  @media only screen and (max-width: $bp-large) {
    font-size: 45%;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 40%;
  }

  @media only screen and (max-width: $bp-small) {
    font-size: 35%;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: 'Courier Prime', monospace;
  font-weight: 400;
  line-height: 1.6;
  color: var(--color-primary);

  min-height: 100vh;
}