@import '../../common-sass/media.scss';

.success-main-container {
  width: 100%;
  height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: fadeIn 2s ease-out;

  .success-message-container {
    position: relative;
    width: 60vw;
    min-height: 50vh;
    padding: 1.5rem 1.5rem;

    display: flex;
    flex-direction: row;

    &::before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(white, .8);
      border-radius: 1rem;
      border: solid .5rem var(--color-primary);
    }

    @media only screen and (max-width: $bp-largest) {
      width: 85vw;
    }

    @media only screen and (max-width: $bp-medium) {
      width: 90vw;
    }


    .game-card {
      width: 25%;
      border: solid .5rem var(--color-primary);
      border-radius: 1rem;
      position: relative;
      background-size: cover;
      background-position: center;

      animation: fadeIn 2s ease-out 1s;
      animation-fill-mode: backwards;

      &:hover{
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }

      @media only screen and (max-width: $bp-small) {
        display: none;
      }
    }

    .content {
      margin-left: 2rem;
      width: 100%;
      z-index: 10;
      position: relative;

      display: flex;
      flex-direction: column;

      @media only screen and (max-width: $bp-small) {
        margin-left: 1rem;
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
          font-family: 'Suez One', sans-serif;
          font-size: 5rem;
          color: var(--color-primary);

          animation: fadeIn 2s ease-out 1s;
          animation-fill-mode: backwards;
        }

        .flag-icon-container {
          margin-top: 1rem;

          .flag-icon {
            font-size: 5rem;
            border-radius: 1rem;
            margin-right: .5rem;

            animation: fadeIn 2s ease-out 1s;
            animation-fill-mode: backwards;
          }
        }
      }

      .welcome {
        margin: 0;
        margin-top: 1rem;
        font-family: 'Courier Prime';
        font-size: 1.5rem;

        animation: fadeIn 2s ease-out 1.5s;
        animation-fill-mode: backwards;

        @media only screen and (max-width: $bp-largest) {
          font-size: 250%;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 200%;
          text-align: center;
        }

        .highlight {
          font-style: italic;
          font-weight: bolder;
          font-size: 1.5rem;
          color: var(--color-primary-highlight);

          @media only screen and (max-width: $bp-largest) {
            font-size: 100%;
          }
        }
      }
    }
  }
}