@import '../../common-sass/media.scss';

.section-about {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .section-border {
    width: 100%;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: url(../../../public/assets/about.jpg);
    background-size: cover;
    background-position: center;
    filter: grayscale(90%);
    z-index: 1;
  }

  .about-us-container {
    position: relative;
    flex: 1;
    padding: 10rem;

    .about-us-text-container {
      position: relative;
      z-index: 5;
      background-color:rgba(white, 0.9);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }
    }

    .about-us-header {
      text-align: center;
      padding: 2rem;
      font-size: 5rem;
      font-family: 'Courier Prime', sans-serif;
      text-transform: capitalize;

      @media only screen and (max-width: $bp-medium) {
        font-size: 2.8rem;
      }
    }

    .about-us-text {
      text-align: center;
      padding: 3rem 3rem;
      font-size: 1.5rem;
      font-family: 'Roboto', sans-serif;

      .main-high {
        font-size: 1.5rem;
        color: green;
        // font-weight: bolder;
        font-family: 'Suez One', sans-serif;
      }

      .sub-high {
        font-size: 1.5rem;
        color: purple;
        // font-weight: bolder;
        font-family: 'Suez One', sans-serif;
      }
    }
  }
}