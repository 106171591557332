@use '../../common-sass/animations.scss';
@import '../../common-sass/media.scss';

.section-home {
  height: 50vh;
  min-height: 30rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: url(../../../public/assets/home.jpg);
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    z-index: 1;
  }

  .home-text-container {
    margin-top: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .company-name {
      font-size: 5rem;
      font-family: 'Suez One', sans-serif;
      text-transform: capitalize;
      padding: 1rem 3rem;
      background-color:rgba(white, 0.95);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      z-index: 2;

      animation: moveFromTop 2s ease-out;

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }
    }

    .company-motto {
      text-align: right;
      margin-top: .5rem;
      font-size: 2rem;
      font-family: 'Suez One', sans-serif;
      text-transform: capitalize;
      padding: 1rem 3rem;
      background-color:rgba(white, 0.95);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      z-index: 2;

      animation: moveFromTop 2s ease-out 1s;
      animation-fill-mode: backwards;

      @media only screen and (max-width: $bp-large) {
        font-size: 400%;
      }

      @media only screen and (max-width: $bp-medium) {
        font-size: 320%;
      }

      @media only screen and (max-width: $bp-small) {
        font-size: 300%;
      }

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }
    }
  }

  .btn-explore-container {
    position: relative;
    z-index: 2;
    margin-bottom: 3vh;

    animation: moveFromBottom 1s ease-out 2s;
    animation-fill-mode: backwards;

    @media only screen and (max-height: 500px) {
      display: none;
    }

    .btn-explore {
      @media only screen and (max-width: $bp-large) {
        font-size: 400%;
      }

      @media only screen and (max-width: $bp-medium) {
        font-size: 300%;
      }
    }
  }
}