@use '../../common-sass/animations.scss';
@import '../../common-sass/media.scss';

.section-reviews {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .section-border {
    width: 100%;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: url(../../../public/assets/reviews.jpg);
    background-size: cover;
    background-position: center;
    filter: grayscale(75%);
    z-index: 1;
    opacity: .9;
  }

  .reviews-container {
    width: 80vw;
    position: relative;
    flex: 1;
    padding: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;


    @media only screen and (max-width: $bp-medium) {
      width: 90vw;
      padding: 5rem;
    }

    .reviews-header {
      background-color:rgba(white, 0.9);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      text-align: center;
      padding: 2rem;
      font-size: 5rem;
      font-family: 'Courier Prime', sans-serif;
      text-transform: capitalize;
      margin-bottom: 2rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: 2.8rem;
      }

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }
    }

    .review-item-container {
      background-color:rgba(white, 0.9);
      border: solid .5rem var(--color-primary);
      border-radius: 1.5rem;
      text-align: center;
      padding: 2rem;
      font-size: 2rem;
      margin-bottom: 2rem;

      &:hover {
        background-color: white;
        box-shadow: 0 1rem 2rem rgba(black,.8);
      }

      .review-game {
        font-family: 'Suez One', sans-serif;
        text-align: left;
      }

      .review-comment {
        font-family: 'Roboto', sans-serif;
        text-align: left;
        font-size: 1.5rem;
        font-style: italic;
      }

      .review-reviewer {
        font-family: 'Courier Prime', sans-serif;
        text-align: right;
        font-size: 1.5rem;
      }

      .review-link {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 2rem;

        .review-link-btn {
          color: white;
          font-size: 1.5rem;
          text-transform: capitalize;
          padding: .5rem 2rem;
          background-color: var(--color-primary);
          border-radius: 1rem;
          border-style: solid;
          border-width: .1rem;
          border-color: var(--color-primary);
          transition: all .2s;

          &:hover {
            box-shadow: 0 0 .3rem .3rem rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}